import $ from "jquery";

export default class TableHelper {
    constructor() {
        console.log('TableHelper: construct')
    }

    init() {
        this.attachHandlers();
    }

    attachHandlers() {
        let toggler = $('input[type="checkbox"][data-toggle-checkboxes]');
        toggler.on('click', (e) => {
            let theParent = $(e.target).parents('table').eq(0);
            let checked = theParent.find('input[type="checkbox"]:checked').not('[data-toggle-checkboxes]');
            let total = theParent.find('input[type="checkbox"]').not('[data-toggle-checkboxes]');
            if(checked.length < total.length){
                total.prop('checked',true)
            }else {
                total.prop('checked',false)
            }
            toggler.prop('checked',false)
        })
    }
}

document.addEventListener("turbolinks:load", function() {
    console.log('tableheper')
    const reg = new TableHelper();
    reg.init();
})
